<template>
    <div>
        <div v-if="videoFile === null && videoSrc === null">
            <el-upload
                ref="upload"
                :show-file-list="false"
                :on-change="handleChange"
                :on-remove="handleRemove"
                :file-list="fileList"
                :auto-upload="false"
                :limit="1"
                drag
                action=""
                :on-exceed="videoOverLimit"
                accept=".mp4"
            >
                <div
                    v-if="videoFile === null && videoSrc === null"
                    class="relative w-full text-center"
                >
                    <div class="el-upload__tip text-gray-400 text-[14px]">
                        上傳影片限制：{{ limitFileSize }}mb
                    </div>
                    <div class="mt-2">
                        <button
                            class="orange-btn-outline-800 md:text-sm text-xs mr-1 w-[113px] h-[35px]"
                            @click.prevent=""
                        >
                            上傳影片
                        </button>
                    </div>
                </div>
            </el-upload>
        </div>
        <div v-else class="flex justify-center">
            <div class="relative max-w-[500px]">
                <div
                    class="absolute top-1 bg-white rounded-full w-[20px] h-[20px] right-2 flex items-center justify-center cursor-pointer z-10"
                    @click="handleRemove()"
                >
                    <i class="fas fa-times"></i>
                </div>
                <video
                    class="max-w-[500px] w-full"
                    style="z-index: -1"
                    :src="videoSrc"
                    controls
                ></video>
            </div>
        </div>
    </div>
</template>

<script >
import { ref, reactive, del, set, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
import { useImageLimit } from "@/service/useHooks";
// 訊息提示套件
import { Message } from "element-ui";
import { isEmpty } from "@/service/anyService";
import store from "@/store";
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: String,
    default() {
      return {};
    }
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const props = __props;
  // 判斷是否有傳值 有傳值 將 JSON 字串 parse 解譯出來
  const propsValue = !isEmpty(props.value) ? JSON.parse(props.value) : props.value;
  const emit = __ctx.emit;
  const {
    proxy
  } = getCurrentInstance();
  // 上傳影片檔
  const videoFile = ref(null);
  // 上傳影片 base64
  const videoSrc = ref(propsValue.videoUrl);
  const limitFileSize = ref(store.state.utilityStore.uiSwitchSettingDatas.upload_photo_size ?? process.env.VUE_APP_UPLOAD_PHOTO_SIZE);
  // const value = ref({});
  // 檔案列表
  const fileList = ref([]);
  const loading = ref(false);

  //影片變更
  async function handleChange(file) {
    console.log("file size =>", file.size);
    // 清空上傳檔案列表 以免造成檔案上傳上限超過數量限制
    fileList.value = [];
    if (file.size > (store.state.utilityStore.uiSwitchSettingDatas.upload_photo_size ?? Number(process.env.VUE_APP_UPLOAD_PHOTO_SIZE)) * 1024 * 1024) {
      Message({
        type: "error",
        message: `影片尺寸不可超過 ${store.state.utilityStore.uiSwitchSettingDatas.upload_photo_size ?? process.env.VUE_APP_UPLOAD_PHOTO_SIZE}MB`
      });
      return;
    }
    // 設定影片為上傳檔案
    videoFile.value = file.raw;
    // 將檔案轉成 base64 預覽
    videoSrc.value = await toBase64(videoFile.value);
    emit("input", {
      videoUrl: "123",
      videoFile: videoFile.value
    });
  }
  // 將影片轉為 base64
  function toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  //移除影片
  function handleRemove() {
    videoFile.value = null;
    videoSrc.value = null;
    emit("input", JSON.stringify({
      videoUrl: null,
      sizes: propsValue.sizes
    }));
  }

  //上傳影片超過限制數量
  function videoOverLimit(files, fileList) {
    Message({
      type: "error",
      message: "超過上傳影片上限"
    });
  }
  function reset() {
    // 上傳影片檔
    videoFile.value = null;
    // 上傳影片 base64
    videoSrc.value = null;
    // 檔案列表
    fileList.value = [];
  }
  return Object.assign({
    videoFile,
    videoSrc,
    limitFileSize,
    fileList,
    handleChange,
    handleRemove,
    videoOverLimit
  }, {
    reset
  });
};
export default __sfc_main;
</script>

<style lang="scss" scoped>
::v-deep .el-upload-dragger {
    @apply border-none text-left;
}
::v-deep .el-upload-list {
    @apply sr-only;
}
::v-deep .el-upload-dragger {
    @apply h-auto w-full overflow-visible;
}
::v-deep .el-upload {
    @apply w-full text-left;
}
</style>
