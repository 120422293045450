<template>
    <div>
        <div v-if="photoFile === null && photoImage === null">
            <el-upload
                ref="upload"
                :show-file-list="false"
                :on-change="handleChange"
                :on-remove="handleRemove"
                :file-list="fileList"
                :auto-upload="false"
                :limit="1"
                drag
                action=""
                :on-exceed="imageOverLimit"
                accept=".jpg,.jpeg,.png,.JPG,.JPEG"
            >
                <div
                    v-if="photoFile === null && photoImage === null"
                    class="relative w-full text-center"
                >
                    <div class="el-upload__tip text-gray-400 text-[14px]">
                        上傳尺寸限制：{{ sizes.width }}*{{
                            sizes.height
                        }}
                        只能上傳jpg/png文件，且不超過{{ limitFileSize }}mb
                    </div>
                    <div class="mt-2">
                        <button
                            class="orange-btn-outline-800 md:text-sm text-xs mr-1 w-[113px] h-[35px]"
                            @click.prevent=""
                        >
                            上傳圖片
                        </button>
                    </div>
                </div>
            </el-upload>
        </div>
        <div v-else class="flex justify-center">
            <div class="relative max-w-[500px]">
                <div
                    class="absolute top-1 bg-white rounded-full w-[20px] h-[20px] right-2 flex items-center justify-center cursor-pointer"
                    @click="handleRemove()"
                >
                    <i class="fas fa-times"></i>
                </div>
                <img
                    class="w-full rounded-lg max-w-[500px]"
                    :src="photoImage"
                    alt=""
                />
            </div>
        </div>
    </div>
</template>

<script >
import { ref, reactive, del, set, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
import { useImageLimit } from "@/service/useHooks";
// 訊息提示套件
import { Message } from "element-ui";
import { isEmpty } from "@/service/anyService";
import store from "@/store";
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: String,
    default: ""
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  /**
   * 因為套件只支援傳送字串值 因此把 JSON stringify 後傳進來
   */
  const props = __props;
  // 判斷是否有傳值 有傳值 將 JSON 字串 parse 解譯出來
  const propsValue = !isEmpty(props.value) ? JSON.parse(props.value) : props.value;
  const emit = __ctx.emit;
  const {
    proxy
  } = getCurrentInstance();
  // 上傳圖片檔
  const photoFile = ref(null);
  // 上傳圖片 base64
  const photoImage = ref(propsValue.imgUrl);
  const sizes = ref(propsValue.sizes);
  const limitFileSize = ref(store.state.utilityStore.uiSwitchSettingDatas.upload_photo_size ?? process.env.VUE_APP_UPLOAD_PHOTO_SIZE);
  // const value = ref({});
  // 檔案列表
  const fileList = ref([]);
  const loading = ref(false);

  //圖片變更
  async function handleChange(file) {
    // 清空上傳檔案列表 以免造成檔案上傳上限超過數量限制
    fileList.value = [];
    if (file.size > (store.state.utilityStore.uiSwitchSettingDatas.upload_photo_size ?? Number(process.env.VUE_APP_UPLOAD_PHOTO_SIZE)) * 1024 * 1024) {
      Message({
        type: "error",
        message: `圖片尺寸不可超過 ${store.state.utilityStore.uiSwitchSettingDatas.upload_photo_size ?? process.env.VUE_APP_UPLOAD_PHOTO_SIZE}MB`
      });
      return;
    }
    // 設定圖片為上傳檔案
    photoFile.value = file.raw;
    // 將檔案轉成 base64 預覽
    photoImage.value = await toBase64(photoFile.value);
    // 驗證圖片尺寸
    const result = await checkImageLimit(photoImage.value, sizes.value);
    if (!result) {
      return;
    }
    emit("input", {
      imgUrl: "123",
      photoFile: photoFile.value
    });
  }
  // 將圖片轉為 base64
  function toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  //移除圖片
  function handleRemove() {
    photoFile.value = null;
    photoImage.value = null;
    sizes.value = propsValue.sizes;
    emit("input", JSON.stringify({
      imgUrl: null,
      sizes: sizes.value
    }));
  }
  // 判斷圖片尺寸
  async function checkImageLimit(file, sizes) {
    // 當沒有限制尺寸時 直接回傳 true
    if (sizes.width.length === 0) {
      return true;
    }
    try {
      await useImageLimit(file, sizes);
      return true;
    } catch (err) {
      handleRemove();
      alert("圖片不符合寬高限制");
      return false;
    }
  }
  //上傳圖片超過限制數量
  function imageOverLimit(files, fileList) {
    Message({
      type: "error",
      message: "超過上傳圖片上限"
    });
  }
  function reset() {
    // 上傳圖片檔
    photoFile.value = null;
    // 上傳圖片 base64
    photoImage.value = null;
    // 檔案列表
    fileList.value = [];
  }
  return Object.assign({
    photoFile,
    photoImage,
    sizes,
    limitFileSize,
    fileList,
    handleChange,
    handleRemove,
    imageOverLimit
  }, {
    reset
  });
};
export default __sfc_main;
</script>

<style lang="scss" scoped>
::v-deep .el-upload-dragger {
    @apply border-none text-left;
}
::v-deep .el-upload-list {
    @apply sr-only;
}
::v-deep .el-upload-dragger {
    @apply h-auto w-full overflow-visible;
}
::v-deep .el-upload {
    @apply w-full text-left;
}
</style>
