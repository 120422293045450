<template>
    <div class="menu">
        <div class="relative pb-5 bg-white rounded-lg shadow-lg sub-menu">
            <div v-for="(route, index) in sideBarRoutes" :key="index">
                <router-link
                    v-if="checkPermission(route.name)"
                    class="rounded-lg menu-item active"
                    :to="{ name: route.name }"
                >
                    {{ route.meta.text }}
                </router-link>
            </div>
        </div>
        <button
            v-if="!$isEmpty(btnText)"
            v-permission="['create']"
            class="w-full btn-add orange-btn-800-lg"
            @click="clickBtn"
        >
            {{ btnText }}
        </button>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { templateSettingRouter } from "@/router/routers/templateSetRouter";

export default {
    name: "SideBar",
    props: {
        btnText: {
            type: String,
            default: "+ 新增置頂 Banner",
        },
        currentPage: {
            type: Number,
            default: 1,
        },
    },
    computed: {
        ...mapState("permissionStore", ["isAdmin"]),
        routers() {
            return this.$route.meta.params.sideItems
                ? this.$route.meta.params.sideItems
                : [];
        },
    },
    data() {
        return {
            sideBarRoutes: templateSettingRouter.children,
        };
    },
    methods: {
        clickBtn() {
            this.$emit("clickBtn");
        },
        checkPermission(key) {
            if (this.isAdmin) {
                return true;
            }
            if (this.routers.includes(key)) {
                return true;
            }
            return false;
        },
    },
};
</script>

<style lang="scss" scoped>
.menu {
    margin-right: 10px;
    .sub-menu {
        min-width: 248px;
        background: white;
        display: flex;
        flex-direction: column;
        align-content: center;
        padding: 10px 15px;
        .menu-item {
            width: 100%;
            height: 40px;
            text-align: left;
            font-size: 14px;
            padding: 20px 15px;
            cursor: pointer;
            display: flex;
            align-items: center;
            margin: 10px 0;
            &.router-link-active {
                background: #ff583314;
            }
        }
    }
    .btn-add {
        margin-top: 12px;
    }
}
</style>
