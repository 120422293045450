import {
    ref,
    reactive,
    onMounted,
    onActivated,
    computed,
    defineEmits,
    inject,
    getCurrentInstance,
} from "vue";

export async function useImageLimit(file, sizes) {
    var img = new Image();
    img.src = file;
    return new Promise((resolve, reject) => {
        img.onload = () => {
            if (
                !sizes.height.includes(img.height) ||
                !sizes.width.includes(img.width)
            ) {
                reject(false);
            }
            resolve(true);
        };
    });
}
